<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col
          lg="2"
          md="2"
          sm="2"
          class="my-1"
        >
          <b-button-group>

            <btnExportar
              modulo="Productos"
              :items="items"
              :disabledExport="disabledExport"
            />
            <btnImportar
              v-if="$can('create', 'productos')"
              modulo="Productos"
            />

            <modalImportar
              v-if="$can('create', 'productos')"
              modulo="Productos"
            />

          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="items.length"
          />
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <b-col
          md="4"
          sm="4"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="Producto"
            modulo="productos"
            @processAdd="addProducto"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Header: Check -->
            <template #head(colCheck)="data">

              <b-form-checkbox
                :id="data.label"
                v-model="chkTodo"
              />

            </template>

            <!-- Column: Check -->
            <template #cell(colCheck)="data">

              <b-form-checkbox
                :id="`chk-${data.item.id}`"
                v-model="data.item.chkSelected"
              />

            </template>

            <!-- Column: Producto -->
            <template #cell(nombre)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.imagen"
                    :text="avatarText(data.item.nombre)"
                  />
                </template>
                <productosModal
                  :data="data"
                />
                <b-link
                  v-b-modal="`modal-lg-${data.item.id}`"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.nombre }}
                </b-link>
                <small class="text-muted">{{ data.item.codigo }}</small>
              </b-media>
            </template>

            <!-- COLUMNA IDCATEGORIA -->
            <template #cell(nombreCategoria)="data">
              {{ data.item.nombreCategoria }}
              <span
                v-if="data.item.estadoCategoria === 'Inactivo'"
              >
                ({{ data.item.estadoCategoria }})
              </span>
            </template>

            <!-- COLUMNA IDUBICACION -->
            <template #cell(nombreUbicacion)="data">
              {{ data.item.nombreUbicacion }}
              <span
                v-if="data.item.estadoUbicacion === 'Inactivo'"
              >
                ({{ data.item.estadoUbicacion }})
              </span>
            </template>

            <!-- COLUMNA STOCK -->
            <template #cell(stock)="data">
              <colStock :data="data" />
            </template>

            <!-- COLUMNA ESTADO -->
            <template #cell(estado)="data">
              <colEstado
                :data="data"
                modulo="productos"
                @processUpdateEstado="updateEstado"
              />
            </template>

            <!-- Column: Action -->
            <template #cell(acciones)="data">
              <colAccionesBtnes
                modulo="productos"
                :modal="`modal-lg-${data.item.id}`"
                :data="data"
                @processGoToUpdate="goToUpdate"
                @processGoToClone="goToClone"
                @processRemove="remove(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>

      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BMedia, BLink, BAvatar,
  BFormCheckbox, BButtonGroup, BOverlay, BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { avatarText } from '@core/utils/filter'

const btnCrear = () => import('@/layouts/components/Recycled/List/btnCrear.vue')
const inputFiltro = () => import('@/layouts/components/Recycled/List/inputFiltro.vue')
const btnMostrar = () => import('@/layouts/components/Recycled/List/btnMostrar.vue')
const colAccionesBtnes = () => import('@/layouts/components/Recycled/List/colAccionesBtnesProductos.vue')
const colEstado = () => import('@/layouts/components/Recycled/List/colEstado.vue')
const colStock = () => import('@/layouts/components/Recycled/List/colStock.vue')
const productosModal = () => import('@/layouts/components/Productos/components/ProductosModal.vue')
const btnExportar = () => import('./components/btnExportar.vue')
const btnImportar = () => import('@/layouts/components/Recycled/List/btnImportar.vue')
const modalImportar = () => import('./components/modalImportar.vue')
const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BMedia,
    BLink,
    BAvatar,
    BFormCheckbox,
    BButtonGroup,
    BOverlay,
    BCard,

    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
    colStock,
    productosModal,
    btnExportar,
    btnImportar,
    modalImportar,
    spinner,
  },
  data() {
    return {
      cargando: true,
      spinner: false,
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'colCheck',
          label: 'chkHeader',
          sortable: false,
          thStyle: {
            width: '0px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre',
          label: 'Producto',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'codigo',
          label: 'Código',
          sortable: true,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'unidad',
          label: 'Unidad',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '50px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreCategoria',
          label: 'Categoría',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreUbicacion',
          label: 'Ubicación',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'stock',
          label: 'Stock',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '50px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getProductos: 'productos/getProductos' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  watch: {
    getProductos(val) {
      this.totalRows = val.length
      this.items = this.getProductos
    },
    chkTodo() {
      this.chkAll()
    },
  },
  mounted() {
    this.fetchProductos().then(() => {
      this.cargando = false
    })
    this.fetchCategorias()
    this.fetchUbicaciones()
  },
  methods: {
    ...mapActions({
      fetchProductos: 'productos/fetchProductos',
      fetchCategorias: 'categorias/fetchCategorias',
      fetchUbicaciones: 'ubicaciones/fetchUbicaciones',
      updateProductoEstado: 'productos/updateProductoEstado',
      removeProductos: 'productos/removeProductos',
    }),
    ...mapMutations('productos', ['setProducto']),
    ...mapMutations('categorias', ['setCategorias']),
    ...mapMutations('ubicaciones', ['setUbicaciones']),
    addProducto() {
      this.$router.replace({
        name: 'productos-create',
      })
    },
    updateEstado() {
      // console.log('update')
    },
    goToUpdate(producto) {
      this.setProducto(producto)
      this.$router.push({
        name: 'productos-update',
        params: { id: producto.id },
      })
    },
    goToClone(producto) {
      this.setProducto(producto)
      this.$router.push({
        name: 'productos-clone',
        params: { id: producto.id },
      })
    },
    remove(producto) {
      this.$swal({
        title: 'Eliminar producto!',
        text: `Estás seguro que deseas eliminar el producto "${producto.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeProductos(producto.id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminada con éxito!',
              text: `"${producto.nombre}" ha sido eliminada!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
